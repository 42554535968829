import axios from '@/plugins/axios.js'

// 搜索（分页）
export const accountSearch = (params) => {
  let newParams = { ...params }
  newParams.site = params.site == '' ? null : params.site
  return axios.post('/account/search', newParams)
}

// 查询（id）
export const accountQuery = (params) => axios.get('/account/query', { params })

// 导出
export const accountExport = (params) => {
  let newParams = { ...params }
  newParams.site = params.site == '' ? null : params.site
  return axios.post('/account/export', newParams, { responseType: 'blob' })
}

// 模板导出
export const accountTemplateExport = (params) => {
  return axios.post('/account/accountExcel', params, { responseType: 'blob' })
}

// 导入失败数据导出
export const accountExportFailedData = (params) => {
  return axios.post('/account/exportFailedData', params, { responseType: 'blob' })
}

// 删除
export const removeAccount = (params) => axios.delete('/account/remove', { params })

// 批量删除
export const removeAccountBatch = (data) => axios.delete('/account/removeBatch', { data })

// 保存
export const accountSave = (params) => axios.post('/account/add', params)

// 修改
export const accountEdit = (params) => axios.post('/account/update', params)

// 配置账号时查询（siteId）
export const accountConfigQuery = (params) => axios.get('/account/queryAccount', { params })

// 解除设备绑定
export const unbindDevice = (params) => axios.post('/device/unBinded', params)
