<template>
  <div class="page">
    <div class="page-main">
      <div class="page-card">
        <el-form ref="form" :model="form" :rules="rules" label-width="auto">
          <el-form-item :label="$t('sipAccount.accountName')">
            <el-input
              v-model="form.accountName"
              :placeholder="$t('sipAccount.accountNamePla')"
              :maxlength="128"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('sipAccount.displayName')">
            <el-input
              v-model="form.displayName"
              :placeholder="$t('sipAccount.displayNamePla')"
              :maxlength="128"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('sipAccount.userName')" prop="sipUserId">
            <el-input
              v-model="form.sipUserId"
              :placeholder="$t('sipAccount.userNamePla')"
              :maxlength="128"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('sipAccount.authenticateId')">
            <el-input
              v-model="form.authenticateId"
              :placeholder="$t('sipAccount.authenticateIdPla')"
              :maxlength="128"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('sipAccount.authenticatePassword')">
            <el-input
              v-model="form.authenticatePasswd"
              :placeholder="$t('sipAccount.authenticatePasswdPla')"
              :maxlength="128"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('sipAccount.sipServer')" prop="sipServerId">
            <el-select
              v-model="form.sipServerId"
              :placeholder="$t('sipAccount.sipServerPla')"
              style="width: 100%"
            >
              <el-option
                v-for="item in sipServerIdList"
                :key="item.id"
                :label="`${item.serverName}(${item.sipServer})`"
                :value="item.id + ''"
              ></el-option>
            </el-select>
            <div class="form-input-btn" @click="addServer">
              <el-tooltip effect="light" :content="$t('addServer')" placement="top" :open-delay="1000">
                <i class="el-icon-circle-plus-outline"></i>
              </el-tooltip>
            </div>
          </el-form-item>
          <el-form-item :label="$t('sipAccount.assignDevice')">
            <div v-for="(item, i) in form.assignDevice" :key="i">
              <div class="form-inputs">
                <select-model
                  v-model="item.model"
                  clearable
                  :disabled="haveSIPServer"
                  :placeholder="$t('sipAccount.selectModel')"
                  @change="getDevice(item.model, i)"
                  :data.sync="modelList"
                ></select-model>
                <div class="mac-wrap">
                  <el-select
                    :disabled="haveSIPServer"
                    v-model="item.device"
                    :placeholder="$t('sipAccount.selectDevice')"
                    @change="getDeviceAccount(item.device, i)"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in item.deviceList"
                      :key="item.id"
                      :label="`${item.deviceName}(${item.macAddress.toUpperCase()})`"
                      :value="item.id"
                    >
                      <span v-if="item.deviceName">
                        {{ `${item.deviceName}(${item.macAddress.toUpperCase()})` }}
                      </span>
                      <span v-else>{{ item.macAddress.toUpperCase() }}</span>
                    </el-option>
                  </el-select>
                  <div class="el-form-item__error" v-show="item.errText">
                    {{ item.errText }}
                  </div>
                </div>
                <el-select
                  style="margin-right: 0"
                  :disabled="haveSIPServer"
                  v-model="item.account"
                  :placeholder="$t('sipAccount.selectAccount')"
                >
                  <el-option
                    v-for="item in item.accountList"
                    :key="item.accountNo"
                    :label="
                      `Account${item.accountNo}${item.sipUserId ? '(' + item.sipUserId + ')' : ''}`
                    "
                    :value="item.accountNo"
                  >
                    <span>{{ `Account${item.accountNo}` }}</span>
                    <span v-if="item.sipUserId">({{ item.sipUserId }})</span>
                    <span v-else class="text-info">(None)</span>
                  </el-option>
                </el-select>
                <div
                  class="form-input-btn"
                  @click="deleteAssignDevice(i)"
                >
                  <el-tooltip
                    effect="light"
                    :content="$t('delete')"
                    placement="top"
                    :open-delay="1000"
                  >
                    <i class="el-icon-circle-close"></i>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <el-button type="primary" icon="el-icon-plus" @click="addAssignDevice">
              {{ $t('device.addDevice') }}
            </el-button>
          </el-form-item>
          <el-form-item class="ta-r">
            <el-button class="w162" type="primary" plain @click="$router.go(-1)">{{ $t('cancel') }}</el-button>
            <el-button class="w162" type="primary" @click="submit">{{ $t('save') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <dialog-add-server
        v-if="visible"
        :visible.sync="visible"
        @change="(value) => (form.sipServerId = value)"
      ></dialog-add-server>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { accountQuery, accountSave, accountEdit } from '@/api/sipAccount'
import { deviceList, deviceAccountList } from '@/api/device'
import { serverList } from '@/api/sipServer'
import { getNonUniqueIds } from '@/plugins/methods'
import { SelectModel } from '@/components'
import DialogAddServer from './dialog-add-server'

export default {
  name: 'AccountDetails',
  components: {
    DialogAddServer,
    SelectModel,
  },
  data() {
    return {
      haveSIPServer: true,
      pageType: this.$route.query.id ? true : false,
      visible: false,
      form: {
        accountActive: 1,
        accountName: '',
        sipServerId: '',
        sipUserId: '',
        authenticateId: '',
        authenticatePasswd: '',
        displayName: '',
        assignDevice: [
          {
            model: null,
            device: null,
            account: null,
            deviceList: [],
            accountList: [],
          },
        ]
      },
      rules: {
        sipServerId: {
          required: true,
          message: this.$t('sipAccount.sipServerRule'),
          trigger: 'change',
        },
        sipUserId: {
          required: true,
          message: this.$t('sipAccount.userNameRule'),
          trigger: 'blur',
        },
      },
      sipServerIdList: [],
      deviceList: [],
      modelList: []
    }
  },
  computed: {
    ...mapState('memory', ['sidebarSite']),
  },
  created() {
    this.init()
  },
  watch: {
    visible(val) {
      val || this.getServerList()
    },
    deviceList(val) {
      this.pageType || (this.form.assignDevice[0].deviceList = val)
    },
    'form.sipServerId'(val, oldval) {
      const sipServer = this.sipServerIdList.find((item) => item.id === val)
      const OldsipServer = this.sipServerIdList.find((item) => item.id === oldval)
      if (OldsipServer && val && sipServer.siteId !== OldsipServer.siteId) {
        this.form.assignDevice = [
          {
            model: null,
            device: null,
            account: null,
            deviceList: this.deviceList,
            accountList: [],
          },
        ]
      }
      this.haveSIPServer = val !== '' ? false : true
    },
    sidebarSite() {
      this.getServerList()
    },
  },
  methods: {
    async init() {
      await this.getServerList()
      this.pageType && this.getData(this.$route.query.id)
    },
    getData(id) {
      accountQuery({ id })
        .then(({ data }) => {
          this.form = data.result.rows[0]
          let assignDevice = []
          this.form.list.forEach((e) => {
            assignDevice.push({
              model: e.model,
              device: e.deviceId,
              account: e.accountNo + '',
              deviceList: [],
              accountList: [],
            })
          })
          this.$set(this.form, 'assignDevice', assignDevice || [])

          this.form.assignDevice.forEach((e, i) => {
            this.getDevice(e.model, i, true)
            this.getDeviceAccount(e.device, i, true)
          })
        })
        .catch(() => {})
    },
    // 查询服务
    getServerList() {
      return serverList({
        siteId: '',
      })
        .then(({ data }) => {
          this.sipServerIdList = data.result.rows
        })
        .catch(() => {})
    },
    // 查询默认话机
    getDeviceList() {
      deviceList({
        site: null,
        deviceModel: null,
      })
        .then(({ data }) => {
          this.deviceList = data.result.rows
        })
        .catch(() => {})
    },
    // 修改站点
    changeSite() {
      this.form.assignDevice.forEach((e, i) => {
        this.getDevice(e.model, i)
      })
    },
    // 查询话机
    getDevice(model, index, type) {
      const sipServer = this.sipServerIdList.find((item) => item.id === this.form.sipServerId)
      deviceList({
        site: sipServer.siteId,
        deviceModel: model,
      })
        .then(({ data }) => {
          this.$set(this.form.assignDevice[index], 'deviceList', data.result.rows)
          if (!type) {
            this.$set(this.form.assignDevice[index], 'device', null)
            this.$set(this.form.assignDevice[index], 'account', null)
            this.$set(this.form.assignDevice[index], 'accountList', [])
          }
        })
        .catch(() => {})
    },
    // 查询话机账户
    getDeviceAccount(id, index, type) {
      deviceAccountList({ id })
        .then(({ data }) => {
          this.$set(this.form.assignDevice[index], 'accountList', data.result.rows)
          if (!type) {
            this.$set(this.form.assignDevice[index], 'account', null)
          }
        })
        .catch(() => {})
      this.macUniquevalid()
    },
    addServer() {
      this.visible = true
    },
    addAssignDevice() {
      this.form.assignDevice.push({
        model: null,
        device: null,
        account: null,
        deviceList: this.deviceList,
        accountList: [],
      })
    },
    deleteAssignDevice(i) {
      this.form.assignDevice.splice(i, 1)
    },
    // MAC唯一性校验
    macUniquevalid() {
      if (!this.form.assignDevice.length) {
        return false
      }
      if (this.form.assignDevice) {
        let repeatIds = getNonUniqueIds(this.form.assignDevice, 'device')
        this.form.assignDevice.forEach((item) => {
          if (item.device && repeatIds.indexOf(item.device) > -1) {
            item.errText = this.$t('sipAccount.selectDeviceRule')
          } else {
            item.errText = ''
          }
        })
        let validStatus = this.form.assignDevice.every((item) => {
          return item.errText
        })
        return validStatus
      }
      return false
    },
    // 保存
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.macUniquevalid()) return
          let assignDevice = []
          this.form.assignDevice.forEach((e) => {
            if (e.device && e.account) {
              assignDevice.push({
                deviceId: e.device,
                no: e.account,
              })
            }
          })
          const sipServer = this.sipServerIdList.find((item) => item.id === this.form.sipServerId)
          this.form.siteId = sipServer.siteId
          let requestApi = this.pageType ? accountEdit : accountSave  // T-修改 F-新增
          requestApi({ ...this.form, assignDevice })
            .then(({ data }) => {
              this.$message.success(data.message)
              this.$router.go(-1)
            })
            .catch(() => {})
          this.$emit('update:visible', false)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@include form-page;
.mac-wrap {
  margin-bottom: 12px;
  .el-form-item__error {
    position: relative;
    top: 4px;
  }
}
.form-inputs {
  position: relative;
  > div {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
