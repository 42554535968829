import axios from '@/plugins/axios.js'

// 查询（分页）
export const serverSearch = (params) => {
  let newParams = { ...params }
  newParams.site = params.site == '' ? null : params.site
  return axios.post('/server/search', newParams)
}

// 查询（list）
export const serverList = (params) => axios.get('/server/list', { params })

// 查询（id）
export const serverQuery = (params) => axios.get('/server/query', { params })

// 删除
export const removeServer = (data) => axios.delete('/server/remove', { data })

// 批量删除
export const removeServerBatch = (data) => axios.delete('/server/removeBatch', { data })

// 新增
export const serverSave = (params) => axios.post('/server/save', params)
