<template>
  <el-dialog
    :title="$t('sipServer.addServer')"
    :visible.sync="visible"
    width="960px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      class="add-form dialog-add-form"
      ref="form"
      :model="form"
      :rules="rules"
      label-width="165px"
    >
      <el-form-item :label="$t('sipServer.serverName')" prop="serverName">
        <el-input
          v-model="form.serverName"
          maxlength="128"
          :placeholder="$t('sipServer.serverNamePla')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('sipServer.sipServer')" prop="sipServer">
        <el-input
          v-model="form.sipServer"
          maxlength="128"
          :placeholder="$t('sipServer.sipServerPla')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('sipServer.sipServerPort')" prop="port">
        <el-input v-model.number="form.port"></el-input>
      </el-form-item>
      <el-form-item :label="$t('sipServer.transportMode')" prop="transportMode">
        <el-select v-model="form.transportMode" style="width: 100%;">
          <el-option
            v-for="item in transportModeDics"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('ssite.site')" prop="site">
        <select-site
          v-model="form.siteId"
          defaultShow
          clearable
        ></select-site>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button class="footer-btn" type="primary" plain @click="handleClose">{{ $t('cancel') }}</el-button>
      <el-button class="footer-btn" type="primary" @click="submit">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { serverSave } from '@/api/sipServer'
import { SelectSite } from '@/components'

export default {
  name: 'dialog-add-server',
  components: {
    SelectSite,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        serverName: '',
        sipServer: '',
        port: 5060,
        transportMode: '0',
        siteId: '',
      },
      rules: {
        serverName: {
          required: true,
          message: this.$t('sipServer.serverNameRule'),
          trigger: 'blur',
        },
        sipServer: {
          required: true,
          validator: (rule, value, callback) => {
            if (value === '') {
              callback(new Error(this.$t('sipServer.sipServerRule')))
            } else {
              const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
              const regO = /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/
              if (!reg.test(value) && !regO.test(value)) {
                callback(new Error(this.$t('sipServer.sipServerRule')))
              } else {
                callback()
              }
            }
          },
          trigger: 'blur',
        },
        port: {
          required: true,
          type: 'number',
          min: 0,
          max: 65535,
          message: this.$t('sipServer.sipServerPortRule'),
          trigger: 'blur',
        },
        transportMode: {
          required: true,
        },
        site: {
          required: true,
          validator: (rule, value, callback) => {
            if (this.form.siteId === '') {
              callback(new Error(this.$t('site.siteRule')))
            } else {
              callback()
            }
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('dist', ['transportModeDics']),
  },
  methods: {
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 确定
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          serverSave(this.form)
            .then(({ data }) => {
              this.$message.success(data.message)
              this.$emit('change', data.result.rows[0])
              this.$emit('update:visible', false)
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__label{
  padding-right: 10px;
  position: relative;
  right: 10px;
}
</style>